<template>
  <v-card class="historique">
    <v-row no-gutters>
      <v-col cols="9">
        <h2>HISTORIQUE DE COLLECTE ({{ historique.length }})</h2>
        <div class="line"></div>
      </v-col>
      <v-col cols="3">
        <v-icon size="60px" class="history-icon" color="orange darken-2"
          >mdi-history</v-icon
        >
      </v-col>
    </v-row>
    <v-row
      no-gutters
      class="tal noborder pnopmargin"
      v-if="historique.length > 0"
    >
      <v-col class="lowTxt Txtgrey" cols="3" lg="4"><p>DATE</p></v-col>
      <v-col class="lowTxt Txtgrey text-center" cols="3"><p>CUBAGE</p></v-col>
      <v-col class="lowTxt Txtgrey text-center" cols="3"><p>POIDS</p></v-col>
      <v-col class="lowTxt Txtgrey" cols="3" lg="2"></v-col>
    </v-row>
    <v-row
      no-gutters
      class="tal pnopmargin border"
      align="center"
      justify="center"
      v-for="collect in historique"
      :key="collect.id"
    >
      <v-col
        v-if="!tablet"
        align-lg="left"
        align-md="center"
        class="lowTxt uppercase"
        cols="3"
        lg="4"
        ><p>{{ $moment(collect.date_collecte).format("LL") }}</p></v-col
      >
      <v-col
        v-if="tablet"
        align-lg="left"
        align-md="center"
        class="lowTxt uppercase"
        cols="3"
        lg="4"
        ><p>{{ $moment(collect.date_collecte).format("DD/MM/YY") }}</p></v-col
      >
      <v-col class="bold" align="center" cols="3"
        ><p>{{ collect.volume.toFixed(2) }} m³</p></v-col
      >
      <v-col class="bold" align="center" cols="3"
        ><p>{{ collect.poids.toFixed(2) }} kg</p></v-col
      >
      <v-col class="lowTxt bold" align="center" cols="3" lg="2"
        ><a @click="showDetails(collect)" class="details">voir le détail</a></v-col
      >
    </v-row>
    <div v-if="historique.length > 0" class="line w100"></div>
    <v-row v-if="historique.length > 0" no-gutters class="tal">
      <v-col class="lowTxt bold Txtorange" cols="4"><p>TOTAL</p></v-col>
      <v-col class="bold text-center" cols="3"
        ><p>{{ vTotal.toFixed(2) }} m³</p></v-col
      >
      <v-col class="bold text-center" cols="3"
        ><p>{{ pTotal.toFixed(2) }} kg</p></v-col
      >
      <v-col class="lowTxt Txtgrey" cols="2"></v-col>
    </v-row>
    <v-row class="noHistorique" v-if="historique.length == 0">
      <v-col>
        <p>Pas d'historique pour le moment</p>
      </v-col>
    </v-row>
    <v-row>
    <HistoriqueCollectPop
      :show="showPopup"
      @close="showPopup = false, collecte.Collectibles_a_Recuperers.pop()"
      :collecte="collecte"
      :client="$store.state.client"
      :title="title"
    />
    </v-row>
  </v-card>
</template>
<script>
import HistoriqueCollectPop from "@/components/popups/historiqueCollectePop";

export default {
  data() {
    return {
      showPopup: false,
      title: "historique de collecte",
      collecte: {}
      // vTotal:0,
      // pTotal:0
    };
  },
  components: { HistoriqueCollectPop },
  props: {
    historique: {
      type: Array,
      default: () => [],
    },
    vTotal: {
      type: Number,
      default: () => 0,
    },
    pTotal: {
      type: Number,
      default: () => 0,
    },
    tablet: {
      type: Boolean,
      default: () => false,
    },
  },
  methods: {
    showDetails(collecte) {
      console.log("collecte - voir detail", collecte)
      var total = {
        quantite: "",
        Collectible: {
          volume: collecte.volume,
          poids: collecte.poids,
          Categorie: {
            url_logo: "TOTAL"
          }
        }
      }
      console.log("store client", this.$store.state.client)
      this.collecte = collecte
      this.collecte.Collectibles_a_Recuperers.push(total)
      this.showPopup = true;
      // this.collecte.Collectibles_a_Recuperers.pop()
    }
  },
  created() {
    // this.historique.forEach(collect => {
    //   this.vTotal += collect.volume
    //   this.pTotal += collect.poids
    // });
  },
};
</script>
<style scoped>
@import "./historique.css";
</style>
