<template>
  <v-card class="commentaires">
    <v-row no-gutters>
      <v-col cols="9">
        <h2>COMMENTAIRES ({{ commentairesPagination.totalItems }})</h2>
      </v-col>
      <v-col cols="3">
        <img
          class="logo"
          src="@/assets/commentaire.png"
          style="max-width: 60%; float: right"
        />
      </v-col>
    </v-row>

    <div
      class="mt-4"
      v-for="(commentaire, i) in commentairesPagination.rows"
      :key="commentaire.id"
    >
      <!-- commentaire en petit -->
      <transition name="expand">
        <v-row
          class="header border"
          no-gutters
          v-if="!commentaire.show"
          @click="showCommentaire(i)"
        >
          <v-col cols="4" class="italic">
            {{ $moment(commentaire.date_creation).format("ll") }}
          </v-col>
          <v-col cols="8" align="left">
            {{ commentaire.commentaire }}
          </v-col>
        </v-row>
      </transition>
      <!-- commentaire en grand -->
      <transition name="expand">
        <v-row
          class="header notCommentaire"
          no-gutters
          v-if="commentaire.show"
          @click="showCommentaire(i)"
        >
          <v-col cols="12"
            ><p class="uppercase bold">
              {{ $moment(commentaire.date_creation).format("LL") }} -
              {{
                $moment(commentaire.date_creation)
                  .local()
                  .format("HH[h]mm")
              }}
              - {{ commentaire.auteur }}
            </p>
          </v-col>
          <v-col cols="12" class="border">
            <p
              class="mb-0 text-left"
              v-html="
                commentaire.commentaire.replace(/(?:\r\n|\r|\n|\n\r)/g, '<br/>')
              "
            ></p>
          </v-col>
        </v-row>
      </transition>
    </div>
    <v-row class="noComment" v-if="commentairesPagination.totalItems == 0">
      <v-col>
        <p>Pas de commentaire pour le moment</p>
      </v-col>
    </v-row>
    <v-row no-gutters class="mt-8 mb-3">
      <v-col>
        <v-pagination
          v-if="commentairesPagination.totalPages > 1"
          v-model="page"
          :length="commentairesPagination.totalPages"
          circle
          class="mt-6"
        ></v-pagination>
      </v-col>
    </v-row>
    <v-row no-gutters class="mt-8 mb-3">
      <v-col>
        <v-btn
          @click="showPopup = true"
          class="round white--text"
          color="#0084C5"
          block
          large
          light
          depressed
          :disabled="disabledBtn"
        >
          AJOUTER UN COMMENTAIRE
        </v-btn>
      </v-col>
    </v-row>
    <popup
      :show="showPopup"
      :title="title"
      :client="client"
      :commentaire.sync="commentaire"
      :btn="btn"
      @backlist="addCommentaire"
      @close="showPopup = false"
      @errorFields="errorFields"
    ></popup>
  </v-card>
</template>
<script>
import popup from "../../popups/popup.vue";
export default {
  data() {
    return {
      showPopup: false,
      title: "Ajouter un commentaire",
      commentaire: "",
      btn: "Valider votre commentaire",
      page: 1,
    };
  },
  props: {
    commentaires: {
      type: Array,
      default: () => [],
    },
    client: {
      type: Object,
      default: () => {},
    },
    commentairesPagination: {
      type: Object,
      default: () => {},
    },
  },
  components: { popup },
  methods: {
    showCommentaire(index) {
      this.$emit("showCommentaire", index);
    },
    addCommentaire(value) {
      this.$emit("addCommentaire", value);
      this.showPopup = false;
    },
    errorFields(value) {
      console.log("emit dans commentaire");
      this.$emit("errorFields", value);
    },
  },
  computed: {
    disabledBtn() {
      if (this.$route.name == "createClient") {
        return true;
      } else {
        return false;
      }
    },
  },
  watch: {
    page(newValue, oldValue) {
      if (newValue != oldValue) {
        console.log("changement de page", newValue - 1);
        this.$emit("page", newValue - 1);
      }
    },
  },
};
</script>
<style scoped>
@import "./commentaires.css";
</style>
