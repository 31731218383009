var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-overlay',{attrs:{"value":_vm.show}},[(_vm.show2 == false)?_c('v-card',{staticClass:"modif",attrs:{"light":""}},[_c('img',{attrs:{"id":"close","src":require("@/assets/close.png")},on:{"click":function($event){return _vm.$emit('close')}}}),_c('div',{staticClass:"scroll"},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"8","xl":"6","offset":"2","xl-offset":"4"}},[_c('h3',{staticClass:"mt-5 uppercasePopup title"},[_vm._v(" "+_vm._s(_vm.title)+" ")])]),_c('v-col',{attrs:{"cols":"8","xl":"6","offset":"2","xl-offset":"4"}},[_c('h4',{staticClass:"text-center"},[(_vm.client.TypeClient !== undefined && _vm.client.TypeClient.typologie == 'particulier')?_c('span',{staticClass:"capitalize"},[_vm._v("Mr ")]):_c('span',[_vm._v(_vm._s(_vm.client.contact_privilegie))]),_c('span',{staticClass:"uppercasePopup"},[_vm._v(_vm._s(_vm.client ? _vm.client.nom : "")+" "+_vm._s(_vm.client ? _vm.client.prenom : ""))]),_c('span',{staticClass:"capitalize"},[_vm._v(" - "+_vm._s(_vm.client && _vm.client.TypeClient ? _vm.client.TypeClient.typologie : ""))])])]),_c('v-col',{attrs:{"cols":"8","xl":"6","offset":"2","xl-offset":"4"}},[_c('h3',{staticClass:"font-weight-light"},[_c('p',{staticClass:"capitalize text-center custom-pink text-h2"},[_vm._v(" "+_vm._s(_vm.$moment( _vm.collecte && _vm.collecte.Creneau ? _vm.collecte.Creneau.date : "" ).format("LL"))+" ")])])]),_c('v-col',{attrs:{"cols":"8","xl":"6","offset":"2","xl-offset":"4"}},[(_vm.collecte.date_appel != null || _vm.collecte.updatedAt != null)?_c('h3',{staticClass:"font-weight-light"},[_c('p',{staticClass:"capitalize text-center custom-pink text-h5"},[_vm._v(" appel du "+_vm._s(_vm.$moment(_vm.collecte && _vm.collecte.date_appel? _vm.collecte.date_appel : _vm.collecte.updatedAt).format("LLL"))+" ")])]):_vm._e()])],1),(
          _vm.client &&
            _vm.client.TypeClient &&
            _vm.client.TypeClient.typologie == 'particulier'
        )?_c('v-row',[_c('v-col',{attrs:{"cols":"8","xl":"6","offset":"2","xl-offset":"4","align":"center"}},[_c('v-btn',{staticClass:"pa-0 detail-btn mt-3",attrs:{"text":"","dark":"","color":"#EA7E2A","depressed":"","link":"","plain":""},on:{"click":function($event){_vm.detailsBool = !_vm.detailsBool}}},[_vm._v("voir le détail "),(_vm.detailsBool == false)?_c('i',{staticClass:"fas fa-caret-down",on:{"click":function($event){_vm.detailsBool = !_vm.detailsBool}}}):_c('i',{staticClass:"fas fa-caret-up",on:{"click":function($event){_vm.detailsBool = !_vm.detailsBool}}})])],1)],1):_vm._e(),(_vm.detailsBool == true)?_c('v-row',{staticClass:"details scroll"},[_c('v-col',{attrs:{"align":"center","cols":"8","offset":"2","xl":"6","xl-offset":"3"}},[_c('v-data-table',{attrs:{"hide-default-footer":"","hide-default-header":"","headers":_vm.headers,"items":_vm.collecte.Collectibles_a_Recuperers,"dense":""},scopedSlots:_vm._u([{key:"item.Collectible.Categorie.url_logo",fn:function(ref){
        var item = ref.item;
return [(item.Collectible.Categorie.url_logo !== 'TOTAL')?_c('img',{attrs:{"width":"40","src":_vm.url + item.Collectible.Categorie.url_logo}}):_c('span',{staticStyle:{"color":"orange","font-weight":"bold"}},[_vm._v(_vm._s(item.Collectible.Categorie.url_logo))])]}},{key:"item.Collectible.libelle",fn:function(ref){
        var item = ref.item;
return [_c('span',{staticClass:"gris"},[_vm._v(_vm._s(item.Collectible.libelle))])]}},{key:"item.Collectible.volume",fn:function(ref){
        var item = ref.item;
return [(item.quantite !== '')?_c('span',{staticClass:"gris"},[_vm._v(_vm._s(item.Collectible.volume)+"m³")]):_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v(_vm._s(item.Collectible.volume)+"m³")])]}},{key:"item.Collectible.poids",fn:function(ref){
        var item = ref.item;
return [(item.quantite !== '')?_c('span',{staticClass:"gris"},[_vm._v(_vm._s(item.Collectible.poids)+"kg")]):_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v(_vm._s(item.Collectible.poids)+"kg")])]}},{key:"item.quantite",fn:function(ref){
        var item = ref.item;
return [(item.quantite !== '')?_c('span',{staticClass:"gris"},[_vm._v("x"+_vm._s(item.quantite))]):_vm._e()]}}],null,true)})],1)],1):_vm._e(),_c('v-row',{staticClass:"mt-4"},[_c('v-col',{attrs:{"cols":"8","offset":"2","xl":"6","xl-offset":"3"}},[_c('p',{staticClass:"text-left libelle_radio"},[_vm._v("État de la collecte")]),(_vm.client && _vm.client.Marche && _vm.client.Marche.marche == 'CCVE')?_c('v-radio-group',{attrs:{"hide-details":""},model:{value:(_vm.collecte.type),callback:function ($$v) {_vm.$set(_vm.collecte, "type", $$v)},expression:"collecte.type"}},[_c('v-radio',{attrs:{"label":"collecte réalisée","value":"collecte réalisée"}}),_c('v-radio',{attrs:{"label":"collecte non conforme","value":"collecte non conforme"}}),_c('v-radio',{attrs:{"label":"collecte non réalisée","value":"collecte non réalisée"}}),_c('v-radio',{attrs:{"label":"collecte annulée","value":"collecte annulée"}}),_c('v-radio',{attrs:{"label":"collecte supprimée","value":"collecte supprimée"}})],1):_vm._e(),(
              _vm.client && _vm.client.Marche && _vm.client.Marche.marche == 'SIREDOM'
            )?_c('v-radio-group',{attrs:{"hide-details":""},model:{value:(_vm.collecte.type),callback:function ($$v) {_vm.$set(_vm.collecte, "type", $$v)},expression:"collecte.type"}},[_c('v-radio',{attrs:{"label":"collecte réalisée","value":"collecte réalisée"}}),_c('v-radio',{attrs:{"label":"collecte non réalisée non conforme","value":"collecte non réalisée non conforme"}}),_c('v-radio',{attrs:{"label":"collecte non réalisée administré absent","value":"collecte non réalisée administré absent"}}),_c('v-radio',{attrs:{"label":"collecte annulée","value":"collecte annulée"}}),_c('v-radio',{attrs:{"label":"collecte supprimée","value":"collecte supprimée"}})],1):_vm._e()],1)],1),(
          _vm.collecte.type == 'collecte non réalisée' ||
            _vm.collecte.type == 'collecte non conforme' ||
            _vm.collecte.type == 'collecte non réalisée non conforme' ||
            _vm.collecte.type == 'collecte non réalisée administré absent' ||
            _vm.collecte.type == 'collecte annulée'
        )?_c('v-row',[_c('v-col',{attrs:{"cols":"8","offset":"2","xl":"6","xl-offset":"3"}},[_c('v-text-field',{attrs:{"label":"Commentaire","single-line":"","outlined":"","hide-details":""},model:{value:(_vm.collecte.commentaire),callback:function ($$v) {_vm.$set(_vm.collecte, "commentaire", $$v)},expression:"collecte.commentaire"}})],1)],1):_vm._e(),(_vm.client && _vm.client.Marche && _vm.client.Marche.marche == 'SIREDOM')?_c('v-row',[_c('v-col',{attrs:{"cols":"8","offset":"2","xl":"6","xl-offset":"3"}},[_c('p',{staticClass:"text-left libelle_radio"},[_vm._v("Carte identité / PMR")]),_c('v-radio-group',{attrs:{"hide-details":"","row":""},model:{value:(_vm.collecte.possede_carte),callback:function ($$v) {_vm.$set(_vm.collecte, "possede_carte", $$v)},expression:"collecte.possede_carte"}},[_c('v-radio',{attrs:{"label":"Oui","value":true}}),_c('v-radio',{attrs:{"label":"Non","value":false}})],1)],1)],1):_vm._e(),_c('v-row',{staticClass:"my-4"},[_c('v-col',{attrs:{"align":"center"}},[_c('v-btn',{staticClass:"\n            rounded-lg\n            white--text\n            modify-btn\n            font-weight-bold\n          ",attrs:{"align":"center","justify":"center","color":"#EA7E2A"},on:{"click":function($event){return _vm.modifyState()}}},[_vm._v("valider")])],1)],1)],1)]):_vm._e(),_c('div',{attrs:{"id":"snack"}},_vm._l((_vm.errors),function(error){return _c('v-snackbar',{key:error,attrs:{"timeout":_vm.timeout,"right":"","rounded":"pill","color":_vm.color,"elevation":"24"},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(error)+" ")])}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }